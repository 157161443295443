<template>
  <b-row>
    <b-col cols="12">
      <b-card>
        <b-row>
          <b-col cols="12">
            <h2>{{ title }}</h2>
          </b-col>

        </b-row>
        <b-row>
          <b-col
            v-if="withSearch"
            cols="12"
            lg="4"
            class="ml-auto mb-2"
          >
            <b-input-group class="ml-auto">
              <b-form-input
                v-model="search"
                :placeholder="$t('search')"
              />
              <b-input-group-append>
                <b-input-group-text class="bg-primary text-white">
                  {{ $t('search') }}
                </b-input-group-text>
              </b-input-group-append>
            </b-input-group>
          </b-col>
          <b-col cols="12">
            <vue-good-table
              style-class="vgt-table striped"
              :columns="columns"
              :rows="rows"
              max-height="650px"
              :fixed-header="false"
              :pagination-options="{
                enabled: withPagination,
                perPage: pageLength,
              }"
              :search-options="{
                enable: withSearch,
                externalQuery: search
              }"
              :select-options="{
                enabled: withSelectOption,
                selectOnCheckboxOnly: true, // only select when checkbox is clicked instead of the row
                clearSelectionText: 'clear',
                disableSelectInfo: true, // disable the select info panel on top
                selectAllByGroup: true, // when used in combination with a grouped table, add a checkbox in the header row to check/uncheck the entire group
              }"
              :sort-options="{
                enable: true,
              }"
              @on-selected-rows-change="selectedRows"
            >
              <div
                slot="emptystate"
                class="text-center"
              >
                <span v-if="!isLoading">No Data Found</span>
                <b-spinner
                  v-else
                  variant="primary"
                />
              </div>
              <template
                slot="table-row"
                slot-scope="props"
              >
                <slot :props="props" />
              </template>
              <!-- pagination -->
              <template
                slot="pagination-bottom"
                slot-scope="props"
              >
                <div class="d-flex justify-content-between flex-wrap">
                  <div class="d-flex align-items-center mb-0 mt-1">
                    <span class="text-nowrap"> {{ $t('showing') }} 1 {{ $t('to') }} </span>
                    <b-form-select
                      v-model="pageLength"
                      :options="['10', '20', '50']"
                      class="mx-1"
                      @input="
                        (value) => props.perPageChanged({ currentPerPage: value })
                      "
                    />
                    <span class="text-nowrap"> {{ $t('of') }} {{ props.total }} {{ $t('entries') }} </span>
                  </div>
                  <div>
                    <b-pagination
                      :value="1"
                      :total-rows="props.total"
                      :per-page="pageLength"
                      first-number
                      last-number
                      align="right"
                      prev-class="prev-item"
                      next-class="next-item"
                      class="mt-1 mb-0"
                      @input="(value) => props.pageChanged({ currentPage: value })"
                    >
                      <template #prev-text>
                        <feather-icon
                          icon="ChevronLeftIcon"
                          size="18"
                        />
                      </template>
                      <template #next-text>
                        <feather-icon
                          icon="ChevronRightIcon"
                          size="18"
                        />
                      </template>
                    </b-pagination>
                  </div>
                </div>
              </template>
            </vue-good-table>
          </b-col>
        </b-row>
      </b-card>
    </b-col>
  </b-row>
</template>

<script>
import { VueGoodTable } from 'vue-good-table'

export default {
  name: 'MyTable',
  components: {
    VueGoodTable,
  },
  props: {
    title: {
      type: String,
      default: '',
    },
    columns: {
      required: true,
      type: Array,
    },
    rows: {
      required: true,
      type: Array,
    },
    isLoading: {
      required: true,
      type: Boolean,
      default: false,
    },
    pagination: {
      type: Object,
      default: () => {},
    },
    withSelectOption: {
      type: Boolean,
      default: false,
    },
    withPagination: {
      type: Boolean,
      default: true,
    },
    withSearch: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      search: '',
      pageLength: 10,
    }
  },
  methods: {
    selectedRows(selectedRows) {
      this.$emit('selectedRows', selectedRows)
    },
  },
}
</script>

<style lang="scss" >
  @import '@core/scss/vue/libs/vue-good-table.scss';
</style>
