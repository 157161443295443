var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-row',[_c('b-col',{attrs:{"cols":"12"}},[_c('b-card',[_c('b-row',[_c('b-col',{attrs:{"cols":"12"}},[_c('h2',[_vm._v(_vm._s(_vm.title))])])],1),_c('b-row',[(_vm.withSearch)?_c('b-col',{staticClass:"ml-auto mb-2",attrs:{"cols":"12","lg":"4"}},[_c('b-input-group',{staticClass:"ml-auto"},[_c('b-form-input',{attrs:{"placeholder":_vm.$t('search')},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('b-input-group-append',[_c('b-input-group-text',{staticClass:"bg-primary text-white"},[_vm._v(" "+_vm._s(_vm.$t('search'))+" ")])],1)],1)],1):_vm._e(),_c('b-col',{attrs:{"cols":"12"}},[_c('vue-good-table',{attrs:{"style-class":"vgt-table striped","columns":_vm.columns,"rows":_vm.rows,"max-height":"650px","fixed-header":false,"pagination-options":{
              enabled: _vm.withPagination,
              perPage: _vm.pageLength,
            },"search-options":{
              enable: _vm.withSearch,
              externalQuery: _vm.search
            },"select-options":{
              enabled: _vm.withSelectOption,
              selectOnCheckboxOnly: true, // only select when checkbox is clicked instead of the row
              clearSelectionText: 'clear',
              disableSelectInfo: true, // disable the select info panel on top
              selectAllByGroup: true, // when used in combination with a grouped table, add a checkbox in the header row to check/uncheck the entire group
            },"sort-options":{
              enable: true,
            }},on:{"on-selected-rows-change":_vm.selectedRows},scopedSlots:_vm._u([{key:"table-row",fn:function(props){return [_vm._t("default",null,{"props":props})]}},{key:"pagination-bottom",fn:function(props){return [_c('div',{staticClass:"d-flex justify-content-between flex-wrap"},[_c('div',{staticClass:"d-flex align-items-center mb-0 mt-1"},[_c('span',{staticClass:"text-nowrap"},[_vm._v(" "+_vm._s(_vm.$t('showing'))+" 1 "+_vm._s(_vm.$t('to'))+" ")]),_c('b-form-select',{staticClass:"mx-1",attrs:{"options":['10', '20', '50']},on:{"input":function (value) { return props.perPageChanged({ currentPerPage: value }); }},model:{value:(_vm.pageLength),callback:function ($$v) {_vm.pageLength=$$v},expression:"pageLength"}}),_c('span',{staticClass:"text-nowrap"},[_vm._v(" "+_vm._s(_vm.$t('of'))+" "+_vm._s(props.total)+" "+_vm._s(_vm.$t('entries'))+" ")])],1),_c('div',[_c('b-pagination',{staticClass:"mt-1 mb-0",attrs:{"value":1,"total-rows":props.total,"per-page":_vm.pageLength,"first-number":"","last-number":"","align":"right","prev-class":"prev-item","next-class":"next-item"},on:{"input":function (value) { return props.pageChanged({ currentPage: value }); }},scopedSlots:_vm._u([{key:"prev-text",fn:function(){return [_c('feather-icon',{attrs:{"icon":"ChevronLeftIcon","size":"18"}})]},proxy:true},{key:"next-text",fn:function(){return [_c('feather-icon',{attrs:{"icon":"ChevronRightIcon","size":"18"}})]},proxy:true}],null,true)})],1)])]}}],null,true)},[_c('div',{staticClass:"text-center",attrs:{"slot":"emptystate"},slot:"emptystate"},[(!_vm.isLoading)?_c('span',[_vm._v("No Data Found")]):_c('b-spinner',{attrs:{"variant":"primary"}})],1)])],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }